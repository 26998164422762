import { FC } from 'react';

import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import VSpacing from 'bloko/blocks/vSpacing';

import PriceProductAuction from 'lux/components/Auction/Common/PriceProductAuction';
import { AuctionFieldName } from 'lux/models/auctionData';

const Price: FC = () => {
    const replenishmentControlInfo = useSelectorNonNullable(
        ({ auctionData }) => auctionData.controls[AuctionFieldName.Replenishment]
    );
    const productLoading = useSelectorNonNullable(({ auctionData }) => auctionData.productInfo.loading);
    const product = useSelectorNonNullable(({ auctionData }) => auctionData.productInfo.data);

    return replenishmentControlInfo.value && product ? (
        <PriceProductAuction loading={productLoading} />
    ) : (
        <VSpacing base={4} xs={0} />
    );
};

export default Price;
