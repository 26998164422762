import { ReactNode, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import auctionModalOpenButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/auction/auction_modal_open_button_click';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import BudgetReplenishment from 'lux/components/Auction/Modals/BudgetReplenishmentModal';
import ConfirmationModal from 'lux/components/Auction/Modals/ConfirmationModal';
import AuctionEditingModal from 'lux/components/Auction/Modals/EditAuctionModal';
import HowItWorksModal from 'lux/components/Auction/Modals/HowItWorksModal';
import { QS_PARAM_TO_OPEN_AUCTION_BUDGET_REPLENISH } from 'lux/components/Auction/utils';
import { useNotification } from 'lux/components/Notifications/Provider';
import useOnOffState from 'lux/hooks/useOnOffState';
import useRunEmployerVacancyAction from 'lux/hooks/useRunEmployerVacancyAction';
import { toogleConfirmationModal } from 'lux/models/auctionConfirmationModal';
import {
    AuctionCampaignStatus,
    AuctionData,
    DisabledAuctionData,
    CampaignCreationStatus,
    CurrentVacancyAuctionCampaign,
} from 'lux/models/auctionData';
import fetcher from 'lux/modules/fetcher';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';

const setAuctionData = makeSetStoreField('auctionData');

const GET_AUCTION_DATA_URL = '/shards/vacancy/auction/by_vacancy_id';

declare global {
    interface FetcherGetApi {
        [GET_AUCTION_DATA_URL]: {
            queryParams: {
                vacancyId: string;
            };
            response: AuctionData | DisabledAuctionData;
        };
    }
}

interface UseAuctionEditingModal {
    (params: {
        campaignId: string;
        campaignStatus: AuctionCampaignStatus;
        campaignCreationStatus: CampaignCreationStatus;
        vacancyId: string;
        isWebView?: boolean;
    }): {
        showAuctionEditingModal: () => void;
        renderAuctionEditingModal: (
            onCampaignInfoUpdate?: (data: Partial<CurrentVacancyAuctionCampaign>) => void
        ) => ReactNode;
    };
}

const useAuctionEditingModal: UseAuctionEditingModal = ({
    campaignId,
    campaignStatus,
    campaignCreationStatus,
    vacancyId,
    isWebView = false,
}) => {
    const [auctionModalVisible, showAuctionModal, hideAuctionModal] = useOnOffState(false);
    const [howItWorkModalVisible, showHowItWorksModal, hideHowItWorksModal] = useOnOffState(false);
    const [budgetReplenishmentVisible, showBudgetReplenishmentModal, hideBudgetReplenishmentModal] =
        useOnOffState(false);
    const [loading, startLoading, finishLoading] = useOnOffState(false);
    const { addNotification } = useNotification();

    const dispatch = useDispatch();

    useRunEmployerVacancyAction(QS_PARAM_TO_OPEN_AUCTION_BUDGET_REPLENISH, showBudgetReplenishmentModal);

    const onShowHowItWorksModal = useCallback(() => {
        hideAuctionModal();
        showHowItWorksModal();
    }, [hideAuctionModal, showHowItWorksModal]);

    const onCloseHowItWorksModal = useCallback(() => {
        hideHowItWorksModal();
        showAuctionModal();
    }, [hideHowItWorksModal, showAuctionModal]);

    const onShowBudgetReplenishmentModal = useCallback(() => {
        hideAuctionModal();
        showBudgetReplenishmentModal();
    }, [hideAuctionModal, showBudgetReplenishmentModal]);

    const onCloseBudgetReplenishment = useCallback(() => {
        hideBudgetReplenishmentModal();
        showAuctionModal();
    }, [hideBudgetReplenishmentModal, showAuctionModal]);

    const onConfirmationModalClose = useCallback(() => {
        dispatch(toogleConfirmationModal(false));
        showAuctionModal();
    }, [dispatch, showAuctionModal]);

    const recallAndSetCurrentDataAuctionVacancy = useCallback(async () => {
        const currentDataAuctionVacancy = await fetcher.get(GET_AUCTION_DATA_URL, {
            params: {
                vacancyId,
            },
        });
        dispatch(setAuctionData(currentDataAuctionVacancy as AuctionData));
    }, [dispatch, vacancyId]);

    const showAuctionEditingModal = useCallback(async () => {
        try {
            auctionModalOpenButtonClick({ vacancyId, campaignId, campaignStatus, campaignCreationStatus });
            startLoading();
            showAuctionModal();
            await recallAndSetCurrentDataAuctionVacancy();
        } catch (error) {
            hideAuctionModal();
            defaultRequestErrorHandler(error, addNotification);
        } finally {
            finishLoading();
        }
    }, [
        addNotification,
        campaignCreationStatus,
        campaignId,
        campaignStatus,
        finishLoading,
        hideAuctionModal,
        recallAndSetCurrentDataAuctionVacancy,
        showAuctionModal,
        startLoading,
        vacancyId,
    ]);

    const renderAuctionEditingModal = useCallback(
        (onCampaignInfoUpdate?: (data: Partial<CurrentVacancyAuctionCampaign>) => void) => (
            <>
                {auctionModalVisible && (
                    <AuctionEditingModal
                        isWebView={isWebView}
                        visible={auctionModalVisible}
                        onClose={hideAuctionModal}
                        loading={loading}
                        showHowItWorksModal={onShowHowItWorksModal}
                        showBudgetModal={onShowBudgetReplenishmentModal}
                        onCampaignInfoUpdate={onCampaignInfoUpdate}
                    />
                )}
                <HowItWorksModal onClose={onCloseHowItWorksModal} visible={howItWorkModalVisible} />
                <BudgetReplenishment
                    visible={budgetReplenishmentVisible}
                    onCloseBudgetReplenishment={onCloseBudgetReplenishment}
                    onClose={hideBudgetReplenishmentModal}
                    onCloseAll={() => {
                        hideBudgetReplenishmentModal();
                        hideAuctionModal();
                    }}
                    onCampaignInfoUpdate={onCampaignInfoUpdate}
                />
                <ConfirmationModal onClose={onConfirmationModalClose} />
            </>
        ),
        [
            auctionModalVisible,
            budgetReplenishmentVisible,
            hideAuctionModal,
            hideBudgetReplenishmentModal,
            howItWorkModalVisible,
            loading,
            onCloseBudgetReplenishment,
            onCloseHowItWorksModal,
            onConfirmationModalClose,
            onShowBudgetReplenishmentModal,
            onShowHowItWorksModal,
            isWebView,
        ]
    );

    return { showAuctionEditingModal, renderAuctionEditingModal };
};

export default useAuctionEditingModal;
