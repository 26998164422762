import { FC } from 'react';

import { FormError } from 'bloko/blocks/form';

import { ControlInfo } from 'lux/models/auctionData';

import Error from 'lux/components/Auction/Common/Controls/ErrorWrapper/Error';

import styles from './error-wrapper.less';

interface ErrorWrapperProps {
    controlInfo: ControlInfo;
}

const ErrorWrapper: FC<ErrorWrapperProps> = ({ controlInfo }) => {
    const { error, showError } = controlInfo;

    return (
        <div className={styles.wrapper}>
            <FormError show={showError}>{<Error error={error} />}</FormError>
        </div>
    );
};

export default ErrorWrapper;
