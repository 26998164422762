import { FC } from 'react';

import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';

import { NON_BREAKING_SPACE } from 'lux/modules/symbols';

const VacancyAndArea: FC = () => {
    const areaName = useSelectorNonNullable(({ auctionData }) => auctionData.vacancyInfo.areaName);
    const name = useSelectorNonNullable(({ auctionData }) => auctionData.vacancyInfo.name);

    return (
        <div>
            {name},{NON_BREAKING_SPACE}
            {areaName}
        </div>
    );
};

export default VacancyAndArea;
