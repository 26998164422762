import { useDispatch } from 'react-redux';

import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { LinkChip } from 'bloko/blocks/chips';
import { FormError } from 'bloko/blocks/form';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import format from 'bloko/common/format';
import { LangTrls, TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Strings from 'Utils/Strings';
import Error from 'lux/components/Auction/Common/Controls/ErrorWrapper/Error';
import Replenishment from 'lux/components/Auction/Common/Controls/Replenishment';
import { validateAuctionReplenishment } from 'lux/components/Auction/Common/Controls/validateAuctionReplenishment';
import { debouncedAuctionControlsEdit, getInputChangeAnalyticsParams } from 'lux/components/Auction/utils';
import Currency from 'lux/components/Currency';
import FormatMoney from 'lux/components/FormatMoney';
import translation from 'lux/components/translation';
import { AuctionFieldName, setProductInfoLoading, updateAuctionControlInfo } from 'lux/models/auctionData';
import { CurrencyType } from 'lux/models/currencies.types';

import styles from './styles.less';

const TrlKeys = {
    currency: {
        AZN: 'AZN',
        BYR: 'BYR',
        EUR: 'EUR',
        KZT: 'KZT',
        RUR: 'RUR',
        UAH: 'UAH',
        USD: 'USD',
        UZS: 'UZS',
        GEL: 'GEL',
        KGS: 'KGS',
    },
    placeholder: 'auction.budgetReplenishment.control.placeholder',
    amount: 'auction.budgetReplenishment.amount',
};

const getPlaceholder = (trls: LangTrls, minValue: number, currency: CurrencyType) => {
    const formattedMin = Strings.formatCostInCents(minValue);

    return format(trls[TrlKeys.placeholder], {
        '{0}': formattedMin,
        '{1}': trls[TrlKeys.currency[currency]],
    });
};

const CHIPS_VALUES = ['300000', '500000', '1200000'];

const Control: TranslatedComponent = ({ trls }) => {
    const currency = useSelectorNonNullable(({ auctionData }) => auctionData.currency);
    const replenishmentControl = useSelectorNonNullable(
        ({ auctionData }) => auctionData.controls[AuctionFieldName.Replenishment]
    );
    const budget = useSelectorNonNullable(({ auctionData }) => auctionData.currentVacancyAuctionCampaign?.budget || 0);
    const dispatch = useDispatch();

    const updateReplenishment = (value: string) => {
        const errors = validateAuctionReplenishment({
            minReplenishment: replenishmentControl.minValue,
            replenishment: value,
        });

        const newBudget = String(budget + Number(value));

        if (value !== replenishmentControl.value) {
            dispatch([
                setProductInfoLoading(true),
                updateAuctionControlInfo({
                    fieldName: AuctionFieldName.Replenishment,
                    value,
                    error: errors[AuctionFieldName.Replenishment],
                    showError: false,
                }),
            ]);

            debouncedAuctionControlsEdit(
                dispatch(
                    getInputChangeAnalyticsParams({
                        budget: newBudget,
                    })
                )
            );
        }
    };

    const handleChipClick = (value: string) => () => {
        updateReplenishment(value);
    };

    const handleChange = (value: string) => {
        updateReplenishment(value);
    };

    return (
        <div>
            <Text strong>{trls[TrlKeys.amount]}</Text>
            <VSpacing base={1} />
            <Replenishment
                value={replenishmentControl.value}
                onChange={handleChange}
                placeholder={getPlaceholder(trls, replenishmentControl.minValue, currency)}
                postfix={<Currency value={currency} />}
                invalid={replenishmentControl.showError}
                maxLength={7}
                name={AuctionFieldName.Replenishment}
                onBlur={() => {
                    dispatch(
                        updateAuctionControlInfo({
                            fieldName: AuctionFieldName.Replenishment,
                            showError: Boolean(replenishmentControl.error),
                        })
                    );
                }}
                onFocus={() => {
                    dispatch(
                        updateAuctionControlInfo({
                            fieldName: AuctionFieldName.Replenishment,
                            showError: false,
                        })
                    );
                }}
                min={replenishmentControl.minValue}
                error={replenishmentControl.error}
                data-qa="auction-replenishment-control"
            />
            <div className={styles.auctionReplenishmentControlError}>
                <FormError show={replenishmentControl.showError}>
                    <Error error={replenishmentControl.error} />
                </FormError>
            </div>
            {CHIPS_VALUES.map((value) => (
                <span key={value} className={styles.auctionReplenishmentBudgetChip}>
                    <LinkChip onClick={handleChipClick(value)}>
                        <FormatMoney currency={currency} inCents>
                            {value}
                        </FormatMoney>
                    </LinkChip>
                </span>
            ))}
        </div>
    );
};

export default translation(Control);
