import { useDispatch } from 'react-redux';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import auctionRecommendedBudgetHintButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/auction/auction_recommended_budget_hint_button_click';
import auctionRecommendedBudgetHintElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/auction/auction_recommended_budget_hint_element_shown';
import { Link, Text } from '@hh.ru/magritte-ui';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import { debouncedAuctionControlsEdit, getInputChangeAnalyticsParams } from 'lux/components/Auction/utils';
import FormatMoney from 'lux/components/FormatMoney';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    hint: 'auction.controls.hint.recommendedBudget',
};

interface HintProps {
    bid: string;
    budget: string;
    initialBid: string;
    initialBudget: string;
    onClick: (recommendedBudget: number) => void;
}

const Hint: TranslatedComponent<HintProps> = ({ bid, budget, initialBid, initialBudget, onClick, trls }) => {
    const currency = useSelectorNonNullable(({ auctionData }) => auctionData.currency);
    const vacancyCreateDraftId = useSelector(({ vacancyCreateDraftId }) => vacancyCreateDraftId);
    const vacancyId = useSelectorNonNullable(({ auctionData }) => auctionData.vacancyId);

    const dispatch = useDispatch();

    // LPV вакансия — вакансия, набирающая < 5 откликов.
    // Конфигурация аукциона считается оптимальной, если позволяет набрать не меньше 5 откликов
    // при средней конверсии клика в отклик 5%.
    const recommendedBudget = Number(bid) * 100;

    if (Number(budget) >= recommendedBudget) {
        return null;
    }

    return (
        <ElementShownAnchor
            fn={auctionRecommendedBudgetHintElementShown}
            vacancyId={vacancyId}
            bid={bid}
            budget={budget}
            draftId={vacancyCreateDraftId}
            initialBid={initialBid}
            initialBudget={initialBudget}
        >
            <Text typography="paragraph-4-regular" style="secondary">
                {formatToReactComponent(trls[TrlKeys.hint], {
                    '{0}': (
                        <Link
                            Element="button"
                            inline
                            typography="paragraph-4-regular"
                            onClick={() => {
                                auctionRecommendedBudgetHintButtonClick({
                                    vacancyId,
                                    bid,
                                    budget,
                                    draftId: vacancyCreateDraftId,
                                    initialBid,
                                    initialBudget,
                                });
                                onClick(recommendedBudget);
                                debouncedAuctionControlsEdit(
                                    dispatch(
                                        getInputChangeAnalyticsParams({
                                            budget: String(recommendedBudget),
                                        })
                                    )
                                );
                            }}
                        >
                            <FormatMoney currency={currency} inCents>
                                {recommendedBudget}
                            </FormatMoney>
                        </Link>
                    ),
                })}
            </Text>
        </ElementShownAnchor>
    );
};

export default translation(Hint);
