import Button from 'bloko/blocks/button';
import HSpacing from 'bloko/blocks/hSpacing';
import Modal, { ModalFooter, ModalContent, ModalHeader, ModalTitle } from 'bloko/blocks/modal';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AuctionContentBudgetReplenishShown from 'lux/components/Auction/Analytics/AuctionContentBudgetReplenishShown';
import BudgetBanner from 'lux/components/Auction/Common/BudgetBanner';
import AvailableMoney from 'lux/components/Auction/Common/Controls/AvailableMoney';
import translation from 'lux/components/translation';
import { CurrentVacancyAuctionCampaign } from 'lux/models/auctionData';

import Control from 'lux/components/Auction/Modals/BudgetReplenishmentModal/Control';
import Price from 'lux/components/Auction/Modals/BudgetReplenishmentModal/Price';
import SubmitButton from 'lux/components/Auction/Modals/BudgetReplenishmentModal/SubmitButton';

import styles from './styles.less';

const TrlKeys = {
    title: 'auction.replenishment.title',
    back: 'Back',
};

interface BudgetReplenishmentModalProps {
    visible: boolean;
    onCloseBudgetReplenishment: () => void;
    onCampaignInfoUpdate?: (campaign: Partial<CurrentVacancyAuctionCampaign>) => void;
    onCloseAll: () => void;
    onClose: () => void;
}

const BudgetReplenishmentModal: TranslatedComponent<BudgetReplenishmentModalProps> = ({
    trls,
    visible,
    onCloseBudgetReplenishment,
    onCloseAll,
    onCampaignInfoUpdate,
    onClose,
}) => {
    return (
        <Modal visible={visible} onClose={onCloseBudgetReplenishment}>
            <AuctionContentBudgetReplenishShown>
                <ModalHeader>
                    <ModalTitle>{trls[TrlKeys.title]}</ModalTitle>
                </ModalHeader>
                <ModalContent>
                    <div className={styles.auctionReplenishmentContent}>
                        <VSpacing base={3} />
                        <BudgetBanner />
                        <VSpacing base={8} />
                        <Control />
                        <VSpacing base={13} />
                        <AvailableMoney />
                        <Price />
                    </div>
                </ModalContent>
                <ModalFooter>
                    <Button onClick={onCloseBudgetReplenishment}>{trls[TrlKeys.back]}</Button>
                    <HSpacing base={2} />
                    <SubmitButton
                        onClose={onClose}
                        onCloseAll={onCloseAll}
                        onCampaignInfoUpdate={onCampaignInfoUpdate}
                    />
                </ModalFooter>
            </AuctionContentBudgetReplenishShown>
        </Modal>
    );
};

export default translation(BudgetReplenishmentModal);
