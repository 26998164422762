import { useDispatch } from 'react-redux';

import auctionModalEditCampaignButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/auction/auction_modal_edit_campaign_button_click';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import Button, { ButtonKind } from 'bloko/blocks/button';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { getEditCampaignClickAnalyticsParams } from 'lux/components/Auction/utils';
import { useNotification } from 'lux/components/Notifications/Provider';
import translation from 'lux/components/translation';
import useOnOffState from 'lux/hooks/useOnOffState';
import { setConfirmationModalData, toogleConfirmationModal } from 'lux/models/auctionConfirmationModal';
import {
    AuctionData,
    AuctionFieldName,
    CurrentVacancyAuctionCampaign,
    defaultControlInfo,
    updateAuctionControlInfo,
} from 'lux/models/auctionData';
import { CurrencyType } from 'lux/models/currencies.types';
import { useSelector } from 'lux/modules/useSelector';
import { changedClickmeCampaign, createCartWithChangedClickmeCampaign } from 'lux/requests/price/cart';

const setAuctionData = makeSetStoreField('auctionData');

const TrlKeys = {
    purchase: 'employer.auction.price.purchase',
    pay: 'auction.pay',
};

interface SubmitButtonProps {
    onClose: () => void;
    onCloseAll: () => void;
    onCampaignInfoUpdate?: (campaign: Partial<CurrentVacancyAuctionCampaign>) => void;
}

const SubmitButton: TranslatedComponent<SubmitButtonProps> = ({ onClose, onCloseAll, onCampaignInfoUpdate, trls }) => {
    const bidControlInfo = useSelector(
        ({ auctionData }) => auctionData?.controls[AuctionFieldName.Bid] || defaultControlInfo
    );
    const replenishmentControlInfo = useSelector(
        ({ auctionData }) => auctionData?.controls[AuctionFieldName.Replenishment] || defaultControlInfo
    );
    const currentVacancyAuctionCampaign = useSelector(({ auctionData }) => auctionData?.currentVacancyAuctionCampaign);
    const product = useSelector(({ auctionData }) => auctionData?.productInfo.data || null);
    const productLoading = useSelector(({ auctionData }) => auctionData?.productInfo.loading || false);
    const vacancyId = useSelector(({ auctionData }) => auctionData?.vacancyId || null);
    const useAvailableMoney = useSelector(({ auctionData }) => auctionData?.useAvailableMoney);
    const currency = useSelector(({ auctionData }) => auctionData?.currency as CurrencyType);
    const { addNotification } = useNotification();

    const [disabled, setDisabledOn, setDisabledOff] = useOnOffState(false);

    const dispatch = useDispatch();

    const submitIsDisabled =
        !currentVacancyAuctionCampaign ||
        productLoading ||
        (!useAvailableMoney && !product && !!replenishmentControlInfo.value) ||
        !vacancyId ||
        disabled;

    const handleClickChange = () => {
        if (submitIsDisabled || replenishmentControlInfo.error) {
            return;
        }

        setDisabledOn();

        const { budget: initialCampaignBudget, campaignId } = currentVacancyAuctionCampaign;

        const initialBid = Number(bidControlInfo.initialValue);
        const replenishment = Number(replenishmentControlInfo.value);
        if (!product) {
            const callback = (auction: AuctionData) => {
                onCampaignInfoUpdate?.(auction.currentVacancyAuctionCampaign as CurrentVacancyAuctionCampaign);
                dispatch([
                    setAuctionData(auction),
                    updateAuctionControlInfo({ fieldName: AuctionFieldName.Bid, value: String(bidControlInfo.value) }),
                ]);
                onClose();
                dispatch([toogleConfirmationModal(true), setConfirmationModalData({ currency, price: replenishment })]);
            };
            const onError = () => {
                onCloseAll();
            };
            void dispatch(
                changedClickmeCampaign(
                    vacancyId,
                    campaignId,
                    initialBid,
                    initialCampaignBudget + replenishment,
                    callback,
                    addNotification,
                    onError
                )
            ).finally(setDisabledOff);
        } else {
            void dispatch(
                createCartWithChangedClickmeCampaign(
                    [product],
                    {
                        httmFromLabel: 'clickmePopupEdited',
                        source: 'clickmePopupEdited',
                    },
                    vacancyId,
                    initialBid,
                    initialCampaignBudget + replenishment,
                    campaignId,
                    addNotification
                )
            ).finally(setDisabledOff);
        }

        auctionModalEditCampaignButtonClick(dispatch(getEditCampaignClickAnalyticsParams('BUDGET')));
    };

    return (
        <Button
            kind={ButtonKind.Primary}
            onClick={handleClickChange}
            disabled={submitIsDisabled}
            data-qa="auction-replenishment-button"
        >
            {product ? trls[TrlKeys.purchase] : trls[TrlKeys.pay]}
        </Button>
    );
};

export default translation(SubmitButton);
