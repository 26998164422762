import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import Button, { ButtonKind } from 'bloko/blocks/button';
import Modal, { ModalContent, ModalFooter, ModalHeader, ModalTitle } from 'bloko/blocks/modal';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import FormatMoney from 'lux/components/FormatMoney';
import translation from 'lux/components/translation';

import styles from './styles.less';

const TrlKeys = {
    title: 'auction.confirmationModal.title',
    text: 'auction.confirmationModal.text',
    button: 'auction.confirmationModal.button',
};

interface ConfirmationModalProps {
    onClose: () => void;
}

const ConfirmationModal: TranslatedComponent<ConfirmationModalProps> = ({ trls, onClose }) => {
    const { visible, data } = useSelectorNonNullable(({ auctionConfirmationModal }) => auctionConfirmationModal);

    return (
        <Modal visible={visible} onClose={onClose}>
            <ModalHeader>
                <ModalTitle>{trls[TrlKeys.title]}</ModalTitle>
            </ModalHeader>
            <ModalContent>
                <div className={styles.modalContentInner}>
                    {formatToReactComponent(trls[TrlKeys.text], {
                        '{0}': (
                            <FormatMoney currency={data.currency} inCents>
                                {data.price}
                            </FormatMoney>
                        ),
                    })}
                </div>
            </ModalContent>
            <ModalFooter>
                <Button kind={ButtonKind.Primary} onClick={onClose}>
                    {trls[TrlKeys.button]}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default translation(ConfirmationModal);
