import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import auctionHowItWorksButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/auction/auction_how_it_works_button_click';
import Button, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import HSpacing from 'bloko/blocks/hSpacing';
import BlokoLink, { LinkAppearance } from 'bloko/blocks/link';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import Modal, { ModalContent, ModalFooter, ModalHeader, ModalTitle } from 'bloko/blocks/modal';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import AuctionContentStatisticsShown from 'lux/components/Auction/Analytics/AuctionContentStatisticsShown';
import BidRating from 'lux/components/Auction/Common/BidRating';
import BudgetBanner from 'lux/components/Auction/Common/BudgetBanner';
import translation from 'lux/components/translation';
import { CampaignCreationStatus, CurrentVacancyAuctionCampaign } from 'lux/models/auctionData';
import { useSelector } from 'lux/modules/useSelector';

import BidControl from 'lux/components/Auction/Modals/EditAuctionModal/BidControl';
import ChangeStatusButton from 'lux/components/Auction/Modals/EditAuctionModal/ChangeStatusButton';
import SaveButton from 'lux/components/Auction/Modals/EditAuctionModal/SaveButton';
import StatisticsBanner from 'lux/components/Auction/Modals/EditAuctionModal/StatisticsBanner';
import VacancyAndArea from 'lux/components/Auction/Modals/EditAuctionModal/VacancyAndArea';

import styles from './styles.less';

const TrlKeys = {
    title: 'auction.title',
    howItWorksTrigger: 'auction.howItWorks.trigger',
    budgetOpen: 'auction.edit.openBudgetModal',
};

const voidFunction = () => undefined;

interface EditAuctionModalProps {
    visible: boolean;
    onClose: () => void;
    loading: boolean;
    showHowItWorksModal: () => void;
    showBudgetModal: () => void;
    onCampaignInfoUpdate?: (campaign: Partial<CurrentVacancyAuctionCampaign>) => void;
    isWebView: boolean;
}
const EditAuctionModal: TranslatedComponent<EditAuctionModalProps> = ({
    onClose,
    visible,
    loading,
    showHowItWorksModal,
    showBudgetModal,
    onCampaignInfoUpdate,
    trls,
    isWebView = false,
}) => {
    const campaignCreationStatus = useSelector(({ auctionData }) => auctionData?.campaignCreationStatus);
    const campaignId = useSelector(({ auctionData }) => String(auctionData?.currentVacancyAuctionCampaign?.campaignId));
    const campaignStatus = useSelector(({ auctionData }) => auctionData?.currentVacancyAuctionCampaign?.campaignStatus);
    const { pathname, query } = useSelector(({ router }) => router.location);
    const dispatch = useDispatch();
    const url = urlParser(pathname);

    const vacancyId = useSelector(({ auctionData }) => auctionData?.vacancyId);

    const onHowItWorksClick = useCallback(() => {
        auctionHowItWorksButtonClick({
            campaignId,
            campaignCreationStatus: campaignCreationStatus as CampaignCreationStatus,
            // Кампания уже создана, черновика нет
            campaignDraftId: undefined,
            campaignStatus,
            vacancyId,
        });
        showHowItWorksModal();
    }, [campaignCreationStatus, campaignId, campaignStatus, showHowItWorksModal, vacancyId]);

    const updateUrlOnSave = (saved: boolean, hasError?: boolean) => {
        const { error, ...restQuery } = query;
        url.params = { ...restQuery, saved, ...(hasError ? { error: true } : {}) };
        dispatch(push(url.href));
    };

    const onSave = () => {
        if (isWebView) {
            updateUrlOnSave(true);
        } else {
            onClose();
        }
    };

    const onError = () => {
        if (isWebView) {
            updateUrlOnSave(false, true);
        } else {
            onClose();
        }
    };

    const onStopCampaign = () => {
        if (isWebView) {
            updateUrlOnSave(true);
        }
    };

    return (
        <Modal
            visible={visible}
            onClose={isWebView ? voidFunction : onClose}
            closeButtonShow={!isWebView}
            escapePress={!isWebView}
        >
            <ModalHeader>
                <ModalTitle>{trls[TrlKeys.title]}</ModalTitle>
            </ModalHeader>
            <ModalContent>
                {loading ? (
                    <div className={styles.modalLoaderWrapper}>
                        <Loading scale={LoadingScale.Medium} />
                    </div>
                ) : (
                    <div>
                        <VSpacing base={3} xs={4} />
                        <VacancyAndArea />
                        <VSpacing base={8} />
                        <div className={styles.modalContentWrapper}>
                            <div className={styles.modalContentStatistics}>
                                <StatisticsBanner />
                            </div>

                            <AuctionContentStatisticsShown>
                                <BudgetBanner
                                    button={
                                        <Button
                                            appearance={ButtonAppearance.Outlined}
                                            kind={ButtonKind.Primary}
                                            onClick={showBudgetModal}
                                            data-qa="open-auction-replenishment"
                                        >
                                            {trls[TrlKeys.budgetOpen]}
                                        </Button>
                                    }
                                />
                                <VSpacing base={5} />
                                <BidRating>
                                    <VSpacing base={5} />
                                    <BidControl />
                                </BidRating>
                            </AuctionContentStatisticsShown>
                        </div>
                    </div>
                )}
                <VSpacing base={8} xs={0} />
            </ModalContent>
            <ModalFooter>
                <div className={styles.modalFooterInner}>
                    <BlokoLink appearance={LinkAppearance.Pseudo} onClick={onHowItWorksClick}>
                        {trls[TrlKeys.howItWorksTrigger]}
                    </BlokoLink>
                    <span className={styles.modalActions}>
                        <ChangeStatusButton
                            auctionLoading={loading}
                            onStopCampaign={onStopCampaign}
                            onRestoreCampaign={onSave}
                            onCampaignInfoUpdate={onCampaignInfoUpdate}
                        />
                        <HSpacing base={2} />
                        <SaveButton
                            auctionLoading={loading}
                            onSave={onSave}
                            onError={onError}
                            onCampaignInfoUpdate={onCampaignInfoUpdate}
                        />
                    </span>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default translation(EditAuctionModal);
