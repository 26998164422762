import { useCallback, FC } from 'react';
import { useDispatch } from 'react-redux';

import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';

import { debouncedAuctionControlsEdit, getInputChangeAnalyticsParams } from 'lux/components/Auction/utils';
import { AuctionFieldName, updateAuctionControlInfo, setProductInfoLoading } from 'lux/models/auctionData';

import AuctionDummyControls from 'lux/components/Auction/Common/Controls/DummyControls';
import ErrorWrapper from 'lux/components/Auction/Common/Controls/ErrorWrapper';
import Hint from 'lux/components/Auction/Common/Controls/Hint';
import HintOrErrorWrapper from 'lux/components/Auction/Common/Controls/HintOrErrorWrapper';
import { validateAuctionControls } from 'lux/components/Auction/Common/Controls/validateAuctionControls';

const AuctionControls: FC = () => {
    const bidControlInfo = useSelectorNonNullable(({ auctionData }) => auctionData.controls[AuctionFieldName.Bid]);
    const budgetControlInfo = useSelectorNonNullable(
        ({ auctionData }) => auctionData.controls[AuctionFieldName.Budget]
    );
    const dispatch = useDispatch();

    const validateAndChangeAuctionControls = useCallback(
        (bid: string, budget: string, activeFieldName: AuctionFieldName) => {
            const minBid = bidControlInfo.minValue;
            const minBudget = budgetControlInfo.minValue;
            const errors = validateAuctionControls({ bid, budget, minBid, minBudget });

            dispatch([
                updateAuctionControlInfo({
                    fieldName: AuctionFieldName.Bid,
                    value: bid,
                    error: errors[AuctionFieldName.Bid],
                    showError: activeFieldName !== AuctionFieldName.Bid && Boolean(errors[AuctionFieldName.Bid]),
                }),
                updateAuctionControlInfo({
                    fieldName: AuctionFieldName.Budget,
                    value: budget,
                    error: errors[AuctionFieldName.Budget],
                    showError: activeFieldName !== AuctionFieldName.Budget && Boolean(errors[AuctionFieldName.Budget]),
                }),
            ]);
        },
        [bidControlInfo.minValue, budgetControlInfo.minValue, dispatch]
    );

    const budgetPlusOrMinusClick = useCallback(
        (bid: string, budget: string) => {
            const minBid = bidControlInfo.minValue;
            const minBudget = budgetControlInfo.minValue;
            const errors = validateAuctionControls({ bid, budget, minBid, minBudget });

            dispatch([
                setProductInfoLoading(true),
                updateAuctionControlInfo({
                    fieldName: AuctionFieldName.Bid,
                    value: bid,
                    error: errors[AuctionFieldName.Bid],
                    showError: !!errors[AuctionFieldName.Bid],
                }),
                updateAuctionControlInfo({
                    fieldName: AuctionFieldName.Budget,
                    value: budget,
                    error: errors[AuctionFieldName.Budget],
                    showError: !!errors[AuctionFieldName.Budget],
                }),
            ]);
        },
        [bidControlInfo.minValue, budgetControlInfo.minValue, dispatch]
    );

    return (
        <AuctionDummyControls
            bidError={bidControlInfo.error}
            bidShowError={bidControlInfo.showError}
            bidValue={bidControlInfo.value}
            minBid={bidControlInfo.minValue}
            budgetError={budgetControlInfo.error}
            budgetShowError={budgetControlInfo.showError}
            budgetValue={budgetControlInfo.value}
            minBudget={budgetControlInfo.minValue}
            onBidBlur={() => {
                dispatch(
                    updateAuctionControlInfo({
                        fieldName: AuctionFieldName.Bid,
                        showError: Boolean(bidControlInfo.error),
                    })
                );
            }}
            onBidChange={(bid) => {
                validateAndChangeAuctionControls(bid, budgetControlInfo.value, AuctionFieldName.Bid);
            }}
            onBidFocus={() => {
                dispatch(
                    updateAuctionControlInfo({
                        fieldName: AuctionFieldName.Bid,
                        showError: false,
                    })
                );
            }}
            onBudgetBlur={() => {
                dispatch(
                    updateAuctionControlInfo({
                        fieldName: AuctionFieldName.Budget,
                        showError: Boolean(budgetControlInfo.error),
                    })
                );
            }}
            onBudgetChange={(budget) => {
                dispatch(setProductInfoLoading(true));
                validateAndChangeAuctionControls(bidControlInfo.value, budget, AuctionFieldName.Budget);
                debouncedAuctionControlsEdit(dispatch(getInputChangeAnalyticsParams({ budget })));
            }}
            onBudgetFocus={() => {
                dispatch(
                    updateAuctionControlInfo({
                        fieldName: AuctionFieldName.Budget,
                        showError: false,
                    })
                );
            }}
            onBudgetPlusOrMinusClick={(budget) => {
                budgetPlusOrMinusClick(bidControlInfo.value, budget);
                debouncedAuctionControlsEdit(dispatch(getInputChangeAnalyticsParams({ budget })));
            }}
            renderBidError={() => <ErrorWrapper controlInfo={bidControlInfo} />}
            renderBudgetError={() => (
                <HintOrErrorWrapper>
                    {budgetControlInfo.error ? (
                        <ErrorWrapper controlInfo={budgetControlInfo} />
                    ) : (
                        <Hint
                            bid={bidControlInfo.value}
                            budget={budgetControlInfo.value}
                            initialBid={bidControlInfo.initialValue}
                            initialBudget={budgetControlInfo.initialValue}
                            onClick={(recommendedBudget) => {
                                dispatch(setProductInfoLoading(true));
                                validateAndChangeAuctionControls(
                                    bidControlInfo.value,
                                    String(recommendedBudget),
                                    AuctionFieldName.Budget
                                );
                            }}
                        />
                    )}
                </HintOrErrorWrapper>
            )}
        />
    );
};

export default AuctionControls;
