import { useEffect } from 'react';

import urlParser from 'bloko/common/urlParser';

import useUrl from 'lux/hooks/useUrl';

export const RUN_ACTION = 'runAction';

export default (currentActionName: string, actionCb: () => void): void => {
    const url = useUrl();
    const parsedUrl = urlParser(url);
    const actionName = parsedUrl.params?.[RUN_ACTION];

    useEffect(() => {
        if (!actionName || !Array.isArray(actionName)) {
            return;
        }
        if (actionName[0] === currentActionName) {
            const pageHref = new URL(window.location.href);
            const pageParams = new URLSearchParams(pageHref.search);
            pageParams.delete(RUN_ACTION);
            history.replaceState(null, '', `?${String(pageParams)}${location.hash}`);

            actionCb();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
