import { FC, ReactNode, Ref, useRef } from 'react';

import { BottomSheet, BottomSheetFooter, Button, NavigationBar, Text, Tooltip, TooltipHover } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { ActionPropsType, TooltipType } from 'lux/components/FieldTip/types';

interface TipWrapperProps {
    tipContent: ReactNode;
    bottomSheetTitle: string;
    useBottomSheet: boolean;
    visible: boolean;
    onClose: () => void;
    render: (props: { activatorRef?: Ref<HTMLElement> }) => ReactNode;
    tooltipType: TooltipType;
    actionProps?: ActionPropsType;
    action?: string;
    actionClose?: string;
}

const TipWrapper: FC<TipWrapperProps> = ({
    tipContent,
    bottomSheetTitle,
    useBottomSheet,
    visible,
    onClose,
    render,
    tooltipType,
    action,
    actionProps = {},
    actionClose,
}) => {
    const activatorRef = useRef<HTMLElement>(null);

    if (useBottomSheet) {
        return (
            <>
                {render({})}
                <BottomSheet
                    visible={visible}
                    onClose={onClose}
                    header={
                        <NavigationBar
                            title={bottomSheetTitle}
                            showDivider="always"
                            right={{ icon: CrossOutlinedSize24, onClick: onClose }}
                        />
                    }
                    footer={
                        action && (
                            <BottomSheetFooter>
                                <Button
                                    {...actionProps}
                                    onClick={(event) => {
                                        actionProps.onClick?.(event);
                                        onClose();
                                    }}
                                    mode="secondary"
                                >
                                    {action}
                                </Button>
                            </BottomSheetFooter>
                        )
                    }
                >
                    <Text>{tipContent}</Text>
                </BottomSheet>
            </>
        );
    }

    if (tooltipType === TooltipType.Click) {
        return (
            <>
                {render({ activatorRef })}
                <Tooltip
                    direction="right"
                    alignment="top"
                    activatorRef={activatorRef}
                    visible={visible}
                    onClose={onClose}
                    action={action}
                    actionProps={actionProps}
                    actionClose={actionClose}
                >
                    {tipContent}
                </Tooltip>
            </>
        );
    }

    return (
        <>
            {render({ activatorRef })}
            <TooltipHover direction="right" alignment="top" activatorRef={activatorRef}>
                {tipContent}
            </TooltipHover>
        </>
    );
};
export default TipWrapper;
