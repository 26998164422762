import { ReactNode } from 'react';

import { FormLabel, VSpacing, Text } from '@hh.ru/magritte-ui';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AvailableMoney from 'lux/components/Auction/Common/Controls/AvailableMoney';
import Bid from 'lux/components/Auction/Common/Controls/Bid';
import Budget from 'lux/components/Auction/Common/Controls/Budget';
import Currency from 'lux/components/Currency';
import translation from 'lux/components/translation';
import { AuctionFieldError, AuctionFieldName } from 'lux/models/auctionData';
import { useSelector } from 'lux/modules/useSelector';

import styles from './styles.less';

const TrlKeys = {
    bid: 'auction.controls.bid',
    bidInfo: {
        0: 'auction.controls.bid.info.0',
        1: 'auction.controls.bid.info.1',
    },
    budget: 'auction.controls.budget',
    budgetInfo: {
        0: 'auction.controls.budget.info.0',
        1: 'auction.controls.budget.info.1',
    },
};

interface AuctionControlsProps {
    bidError?: AuctionFieldError;
    bidShowError?: boolean;
    redesign?: boolean;
    bidValue: string;
    minBid: number;
    budgetError?: AuctionFieldError;
    budgetShowError?: boolean;
    budgetValue: string;
    minBudget: number;
    inversed?: boolean;
    onBidBlur: () => void;
    onBidChange: (value: string) => void;
    onBidFocus: () => void;
    onBudgetBlur: () => void;
    onBudgetChange: (value: string) => void;
    onBudgetFocus: () => void;
    onBudgetPlusOrMinusClick?: (value: string) => void;
    renderBidError: () => ReactNode;
    renderBudgetError: () => ReactNode;
    onUseAvailableMoneyChange?: (use: boolean) => void;
}

const AuctionDummyControls: TranslatedComponent<AuctionControlsProps> = ({
    bidError,
    bidShowError,
    bidValue,
    minBid,
    budgetError,
    budgetShowError,
    budgetValue,
    minBudget,
    inversed = false,
    onBidBlur,
    onBidChange,
    onBidFocus,
    onBudgetBlur,
    onBudgetChange,
    onBudgetFocus,
    onBudgetPlusOrMinusClick,
    renderBidError,
    renderBudgetError,
    onUseAvailableMoneyChange,
    trls,
}) => {
    const currency = useSelectorNonNullable(({ auctionData }) => auctionData.currency);
    const hideClickmeBalance = useSelector(({ auctionData }) => auctionData?.hideClickmeBalance);

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.inputBlock}>
                    <FormLabel
                        info={
                            <>
                                {trls[TrlKeys.bidInfo[0]]}
                                <VSpacing default={8} />
                                {trls[TrlKeys.bidInfo[1]]}
                            </>
                        }
                    >
                        <Text>{`${trls[TrlKeys.bid]} `}</Text>
                    </FormLabel>
                    <VSpacing default={4} />
                    <div>
                        <Bid
                            inversed={inversed}
                            data-qa="auction-bid-control"
                            invalid={bidShowError}
                            maxLength={7}
                            name={AuctionFieldName.Bid}
                            onBlur={onBidBlur}
                            onChange={onBidChange}
                            onFocus={onBidFocus}
                            value={bidValue}
                            min={minBid}
                            postfix={<Currency value={currency} />}
                            error={bidError}
                            minusDataQa="bid-minus"
                            plusDataQa="bid-plus"
                        />
                        <VSpacing default={8} />
                        {renderBidError()}
                    </div>
                </div>
                <div className={styles.inputSeparator} />
                <div className={styles.inputBlock}>
                    <FormLabel
                        info={
                            <>
                                {trls[TrlKeys.budgetInfo[0]]}
                                <VSpacing default={8} />
                                {trls[TrlKeys.budgetInfo[1]]}
                            </>
                        }
                    >
                        <Text>{`${trls[TrlKeys.budget]} `}</Text>
                    </FormLabel>
                    <VSpacing default={4} />
                    <Budget
                        inversed={inversed}
                        data-qa="auction-budget-control"
                        invalid={budgetShowError}
                        maxLength={7}
                        name={AuctionFieldName.Budget}
                        onBlur={onBudgetBlur}
                        onChange={onBudgetChange}
                        onPlusOrMinusClick={onBudgetPlusOrMinusClick}
                        onFocus={onBudgetFocus}
                        value={budgetValue}
                        min={minBudget}
                        postfix={<Currency value={currency} />}
                        error={budgetError}
                        minusDataQa="budget-minus"
                        plusDataQa="budget-plus"
                    />
                    <VSpacing default={8} />
                    {renderBudgetError()}
                </div>
            </div>
            {!hideClickmeBalance && <AvailableMoney onUseAvailableMoneyChange={onUseAvailableMoneyChange} redesigned />}
        </>
    );
};

export default translation(AuctionDummyControls);
