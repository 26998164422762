import { useRef } from 'react';

import { NumberInput } from '@hh.ru/magritte-ui';
import { NumericInputProps } from 'bloko/blocks/numericInput';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

const CENTS_IN_RUB = 100;

const convertCentsToRubles = (value: string, decimalLength: number): string => {
    if (!value) {
        return '';
    }

    const parsedValue = parseFloat(value);

    if (isNaN(parsedValue)) {
        return '';
    }

    return (parsedValue / CENTS_IN_RUB).toFixed(decimalLength);
};

const convertRublesToCents = (value: string): string => {
    const parsedValue = parseFloat(value);

    if (isNaN(parsedValue)) {
        return '';
    }

    return (parsedValue * CENTS_IN_RUB).toFixed();
};

const formatPrice = (price: string, decimalMark: string): string => {
    const firstChar = price.charAt(0);
    const secondChar = price.charAt(1);
    let formatted = price;

    if (secondChar && firstChar === '0' && secondChar !== decimalMark) {
        formatted = formatted.slice(1);
    }

    return formatted;
};

export interface MoneyInputProps extends NumericInputProps {
    value: string;
}

const MoneyInput: TranslatedComponent<MoneyInputProps> = ({
    decimalLength = 0,
    onChange,
    value,
    decimalMark = ',',
    trls,
    ...inputProps
}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const onInputChange = (value: string) => {
        const formattedValue = formatPrice(value, decimalMark);
        if (inputRef.current) {
            onChange(convertRublesToCents(formattedValue), {
                element: inputRef.current,
            });
        }
    };

    return (
        <NumberInput
            ref={inputRef}
            decimalLength={decimalLength}
            onChange={onInputChange}
            value={convertCentsToRubles(value, decimalLength)}
            decimalMark={[decimalMark]}
            allowNegative={false}
            {...inputProps}
            clearableOnFocus={false}
            size="medium"
            prefix={undefined}
        />
    );
};

export default translation(MoneyInput);
