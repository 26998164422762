import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { FormError } from 'bloko/blocks/form';

import Bid from 'lux/components/Auction/Common/Controls/Bid';
import Error from 'lux/components/Auction/Common/Controls/ErrorWrapper/Error';
import { validateAuctionControls } from 'lux/components/Auction/Common/Controls/validateAuctionControls';
import Currency from 'lux/components/Currency';
import { AuctionFieldName, updateAuctionControlInfo } from 'lux/models/auctionData';

import styles from './styles.less';

const BidControl: FC = () => {
    const bidControl = useSelectorNonNullable(({ auctionData }) => auctionData.controls[AuctionFieldName.Bid]);
    const budgetControl = useSelectorNonNullable(({ auctionData }) => auctionData.controls[AuctionFieldName.Budget]);
    const currency = useSelectorNonNullable(({ auctionData }) => auctionData.currency);

    const dispatch = useDispatch();

    const updateValue = (value: string, showError: boolean) => {
        const errors = validateAuctionControls({
            bid: value,
            budget: budgetControl.value,
            minBid: bidControl.minValue,
            minBudget: budgetControl.minValue,
        });

        dispatch([
            updateAuctionControlInfo({
                fieldName: AuctionFieldName.Bid,
                value,
                error: errors[AuctionFieldName.Bid],
                showError: showError && !!errors[AuctionFieldName.Bid],
            }),
        ]);
    };

    const handleChange = (value: string) => {
        updateValue(value, false);
    };

    const handlePlusMinusClick = (value: string) => {
        updateValue(value, true);
    };

    return (
        <>
            <Bid
                inversed
                invalid={bidControl.showError}
                error={bidControl.error}
                maxLength={7}
                name={AuctionFieldName.Bid}
                min={bidControl.minValue}
                value={bidControl.value}
                onChange={handleChange}
                onBlur={() => {
                    dispatch(
                        updateAuctionControlInfo({
                            fieldName: AuctionFieldName.Bid,
                            showError: Boolean(bidControl.error),
                        })
                    );
                }}
                onFocus={() => {
                    dispatch(
                        updateAuctionControlInfo({
                            fieldName: AuctionFieldName.Bid,
                            showError: false,
                        })
                    );
                }}
                postfix={<Currency value={currency} />}
                onPlusOrMinusClick={handlePlusMinusClick}
                data-qa="auction-bid-control"
                minusDataQa="bid-minus"
                plusDataQa="bid-plus"
            />
            <div className={styles.modalControlError}>
                <FormError show={bidControl.showError}>{<Error error={bidControl.error} />}</FormError>
            </div>
        </>
    );
};

export default BidControl;
