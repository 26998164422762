import { FC } from 'react';

import Label, { LabelColor, LabelType } from 'lux/components/Label';
import { AuctionCampaignStatus } from 'lux/models/auctionData';
import { useSelector } from 'lux/modules/useSelector';

const LabelsStatusMap = {
    [AuctionCampaignStatus.Active]: { type: LabelType.Active, color: LabelColor.Green },
    [AuctionCampaignStatus.Blocked]: { type: LabelType.Pause, color: LabelColor.Gray },
    [AuctionCampaignStatus.CampaignLowBalance]: { type: LabelType.LimitReached, color: LabelColor.Orange },
    [AuctionCampaignStatus.AccountLowBalance]: { type: LabelType.NoMoney, color: LabelColor.Orange },
};

interface CampaignStatusLabelProps {
    dataQaPrefix: string;
}

const CampaignStatusLabel: FC<CampaignStatusLabelProps> = ({ dataQaPrefix }) => {
    const campaignStatus = useSelector(({ auctionData }) => auctionData?.currentVacancyAuctionCampaign?.campaignStatus);

    if (!campaignStatus) {
        return null;
    }

    const labelProps = LabelsStatusMap[campaignStatus] || LabelsStatusMap[AuctionCampaignStatus.Active];

    return (
        <div>
            <Label
                type={labelProps.type}
                color={labelProps.color}
                dataQa={`${dataQaPrefix}-campaign-status-${campaignStatus}`}
            />
        </div>
    );
};

export default CampaignStatusLabel;
