import { FC } from 'react';

import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';

import AuctionInput, { AuctionInputProps } from 'lux/components/Auction/Common/Controls/AuctionInput';
import useFetchProduct from 'lux/components/Auction/hooks/useFetchProduct';
import { AuctionFieldError } from 'lux/models/auctionData';

const BUDGET_STEP = 10000;

interface BudgetProps extends Omit<AuctionInputProps, 'step'> {
    error?: AuctionFieldError;
    onPlusOrMinusClick?: (budget: string) => void;
    minusDataQa?: string;
    plusDataQa?: string;
    inversed?: boolean;
}
const Budget: FC<BudgetProps> = ({ value, error, onPlusOrMinusClick, inversed, ...props }) => {
    const numericValue = Number(value);
    const currentVacancyAuctionCampaign = useSelectorNonNullable(
        ({ auctionData }) => auctionData.currentVacancyAuctionCampaign
    );
    const actualBudget = currentVacancyAuctionCampaign
        ? numericValue - currentVacancyAuctionCampaign.budget
        : numericValue;

    useFetchProduct({ replenishment: actualBudget, error });
    return (
        <AuctionInput
            {...props}
            value={value}
            step={BUDGET_STEP}
            onPlusOrMinusClick={onPlusOrMinusClick}
            inversed={inversed}
        />
    );
};

export default Budget;
