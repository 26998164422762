import { useCallback } from 'react';
import classnames from 'classnames';

import { Card, Loader, Text as MagritteText, VSpacing } from '@hh.ru/magritte-ui';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import FormatMoney from 'lux/components/FormatMoney';
import translation from 'lux/components/translation';
import { BidFrequencyStatus } from 'lux/models/auctionData';
import { useSelector } from 'lux/modules/useSelector';

import styles from './styles.less';

const TrlKeys = {
    [BidFrequencyStatus.Rare]: {
        title: 'auction.bidRating.rare.title',
        description: 'auction.bidRating.rare.description',
    },
    [BidFrequencyStatus.Average]: {
        title: 'auction.bidRating.average.title',
        description: 'auction.bidRating.average.description',
        sudoHint: 'auction.bidRating.average.sudoHint',
    },
    [BidFrequencyStatus.Often]: {
        title: 'auction.bidRating.often.title',
        description: 'auction.bidRating.often.description',
        sudoHint: 'auction.bidRating.often.sudoHint',
    },
};

interface BidRatingProps {
    inversed?: boolean;
    inBuilder?: boolean;
}

const BidRating: TranslatedComponent<BidRatingProps> = ({ inversed, trls, children }) => {
    const bidRatingData = useSelectorNonNullable(({ auctionData }) => auctionData.bidRating.data);
    const currency = useSelectorNonNullable(({ auctionData }) => auctionData.currency);
    const isSudo = useSelector(({ actualAccount }) => actualAccount !== null);
    const loading = useSelectorNonNullable(({ auctionData }) => auctionData.bidRating.loading);

    const { averageThresholdBid, bidFrequencyStatus, oftenThresholdBid } = bidRatingData;

    const renderContent = useCallback(
        (bidFrequencyStatus: BidFrequencyStatus) => (
            <>
                <MagritteText typography="label-2-regular">{trls[TrlKeys[bidFrequencyStatus].title]}</MagritteText>
                <VSpacing default={4} />
                <MagritteText typography="label-3-regular" style="secondary">
                    {bidFrequencyStatus === BidFrequencyStatus.Often
                        ? trls[TrlKeys[BidFrequencyStatus.Often].description]
                        : formatToReactComponent(trls[TrlKeys[bidFrequencyStatus].description], {
                              '{0}': (
                                  <FormatMoney currency={currency} inCents>
                                      {oftenThresholdBid}
                                  </FormatMoney>
                              ),
                          })}
                </MagritteText>
            </>
        ),
        [currency, oftenThresholdBid, trls]
    );

    const bidRatingContent = (
        <div
            className={classnames(styles.bidRatingWrapper, {
                [styles.bidRatingWrapperLoading]: loading && !children,
                [styles.bidRatingWrapperLoadingWithChildren]: loading && children,
            })}
        >
            <div className={styles.bidRatingWrapperContent}>
                <div>{renderContent(bidFrequencyStatus)}</div>
            </div>
            <div className={styles.bidRatingWrapperLoader}>
                <Loader size={24} />
            </div>
            {children}
        </div>
    );

    return (
        <>
            <Card borderRadius={12} padding={24} style={inversed ? undefined : 'extra-1'} stretched actionCard={false}>
                {bidRatingContent}
            </Card>
            {isSudo && (
                <>
                    <VSpacing default={8} />
                    <MagritteText typography="paragraph-4-regular" style="secondary">
                        {formatToReactComponent(trls[TrlKeys[BidFrequencyStatus.Often].sudoHint], {
                            '{0}': (
                                <FormatMoney currency={currency} inCents>
                                    {oftenThresholdBid}
                                </FormatMoney>
                            ),
                        })}
                    </MagritteText>
                    {averageThresholdBid && (
                        <MagritteText typography="paragraph-4-regular" style="secondary">
                            {formatToReactComponent(trls[TrlKeys[BidFrequencyStatus.Average].sudoHint], {
                                '{0}': (
                                    <FormatMoney currency={currency} inCents>
                                        {averageThresholdBid}
                                    </FormatMoney>
                                ),
                            })}
                        </MagritteText>
                    )}
                </>
            )}
        </>
    );
};

export default translation(BidRating);
