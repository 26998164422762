import { CancelToken } from 'axios';

import { ProductType } from 'lux/models/price/product.types';
import { ServiceCode, ServiceItemCode } from 'lux/models/price/productCodes';
import fetcher from 'lux/modules/fetcher';

declare global {
    interface FetcherGetApi {
        '/shards/product_calculator': {
            queryParams: {
                code: ServiceCode | ServiceItemCode;
                count: string;
                isAuction?: boolean;
            };
            response: ProductType;
        };
    }
}

interface FetchCalculatedProductArgs {
    params: {
        code: ServiceCode | ServiceItemCode;
        count: string;
        isAuction?: boolean;
    };
    cancelToken: CancelToken;
}

const fetchCalculatedProduct = ({ params, cancelToken }: FetchCalculatedProductArgs): Promise<ProductType> =>
    fetcher.get('/shards/product_calculator', {
        params,
        cancelToken,
    });

export default fetchCalculatedProduct;
