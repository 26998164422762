import { FC } from 'react';

import { Button } from '@hh.ru/magritte-ui';
import { MinusOutlinedSize24, PlusOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import MoneyInput, { MoneyInputProps } from 'lux/components/MoneyInput';

import styles from './styles.less';

export type AuctionInputProps = MoneyInputProps & {
    step: number;
    onChange: (value: string) => void;
    onPlusOrMinusClick?: (value: string) => void;
    minusDataQa?: string;
    plusDataQa?: string;
    inversed?: boolean;
};

const AuctionInput: FC<AuctionInputProps> = ({
    step,
    value,
    min,
    onPlusOrMinusClick,
    onChange,
    minusDataQa,
    plusDataQa,
    inversed,
    ...inputProps
}) => {
    const numberValue = Number(value);
    const handler = onPlusOrMinusClick || onChange;

    const handleMinus = () => {
        if (min) {
            if (numberValue === min) {
                return;
            } else if (numberValue - step < min) {
                handler?.(String(min));
                return;
            }
        }

        handler?.(String(numberValue - step));
    };
    const handlePlus = () => {
        if (numberValue + step < Number(min)) {
            handler?.(String(min));

            return;
        }

        handler?.(String(numberValue + step));
    };

    return (
        <div className={styles.auctionInput}>
            <MoneyInput value={value} onChange={onChange} {...inputProps} />
            <Button
                onClick={handleMinus}
                mode={inversed ? 'primary' : 'secondary'}
                style={inversed ? 'contrast' : 'neutral'}
                aria-label=""
                hideLabel
                data-qa={minusDataQa}
                icon={<MinusOutlinedSize24 />}
            />
            <Button
                onClick={handlePlus}
                mode={inversed ? 'primary' : 'secondary'}
                style={inversed ? 'contrast' : 'neutral'}
                aria-label=""
                hideLabel
                data-qa={plusDataQa}
                icon={<PlusOutlinedSize24 />}
            />
        </div>
    );
};

export default AuctionInput;
