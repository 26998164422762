import { AnyAction, Dispatch } from 'redux';

import defaultError from 'lux/components/Notifications/DefaultError';
import { AddNotification } from 'lux/components/Notifications/Provider/types';
import { AuctionCampaignStatus, CurrentVacancyAuctionCampaign, updateAuctionStatus } from 'lux/models/auctionData';
import fetcher from 'lux/modules/fetcher';

const CHANGE_AUCTION_CAMPAIGN_STATUS_URL = '/shards/vacancy/auction/update_status_campaign';

declare global {
    interface FetcherPutApi {
        [CHANGE_AUCTION_CAMPAIGN_STATUS_URL]: {
            queryParams: {
                campaignStatus: string;
                campaignId: number;
            };
            body: null;
            response: {
                campaignStatus: AuctionCampaignStatus;
            };
        };
    }
}

export const changeStatusRequest =
    (
        campaignId: number,
        campaignStatus: AuctionCampaignStatus.Active | AuctionCampaignStatus.Blocked,
        callback: (campaign: Partial<CurrentVacancyAuctionCampaign>) => void,
        addNotification: AddNotification
    ) =>
    (dispatch: Dispatch<AnyAction>): Promise<void> =>
        fetcher
            .put(CHANGE_AUCTION_CAMPAIGN_STATUS_URL, null, {
                params: {
                    campaignId,
                    campaignStatus,
                },
            })
            .then(
                ({ data }) => {
                    if (data) {
                        dispatch(updateAuctionStatus(data.campaignStatus));
                        if (callback) {
                            callback(data);
                        }
                    } else {
                        addNotification(defaultError);
                    }
                },
                () => {
                    addNotification(defaultError);
                }
            );
