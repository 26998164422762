import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import Conversion from 'bloko/blocks/conversion';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import FormatMoney from 'lux/components/FormatMoney';
import translation from 'lux/components/translation';
import { AuctionCampaignStatus, AuctionFieldName } from 'lux/models/auctionData';
import { NARROW_NON_BREAKING_SPACE, NON_BREAKING_SPACE, REGULAR_SPACE } from 'lux/modules/symbols';

import styles from './styles.less';

const getClickRemainder = (bid: number, balance: number) => {
    return bid ? Math.floor(balance / bid) : 0;
};

const TrlKeys = {
    title: 'auction.budget.title',
    outOf: 'outOf',
    enoughFor: 'auction.budget.enoughFor',
    click: {
        one: 'click.one',
        some: 'click.some',
        many: 'click.many',
    },
    hint: {
        [AuctionCampaignStatus.CampaignLowBalance]: 'auction.budget.campaignLowBalance',
        [AuctionCampaignStatus.AccountLowBalance]: 'auction.budget.accountLowBalance',
    },
};

interface BudgetBannerProps {
    button?: JSX.Element;
}

const BudgetBanner: TranslatedComponent<BudgetBannerProps> = ({ trls, button }) => {
    const currentCampaign = useSelectorNonNullable(({ auctionData }) => auctionData.currentVacancyAuctionCampaign);
    const bid = useSelectorNonNullable(({ auctionData }) => auctionData.controls[AuctionFieldName.Bid].value || '');
    const currency = useSelectorNonNullable(({ auctionData }) => auctionData.currency);
    const accountBalance = useSelectorNonNullable(({ auctionData }) => auctionData.accountBalance);

    if (!currentCampaign) {
        return null;
    }

    const bidRemainder = getClickRemainder(Number(bid), currentCampaign.campaignBalance);

    return (
        <div className={styles.budgetBanner}>
            <div className={styles.row}>
                <div>
                    <Text strong importance={TextImportance.Primary}>
                        {trls[TrlKeys.title]}
                    </Text>
                    <VSpacing base={3} />
                    <Text importance={TextImportance.Secondary}>
                        <Text Element="span" importance={TextImportance.Primary} size={TextSize.ExtraLarge} strong>
                            <span className={styles.budgetBalance}>
                                <FormatMoney
                                    currency={currency}
                                    groupSeparator={NARROW_NON_BREAKING_SPACE}
                                    inCents
                                    render={(amount, currencyNode) => (
                                        <>
                                            <span data-qa="auction-budget-remainder">{amount}</span>
                                            {NON_BREAKING_SPACE}
                                            {currencyNode}
                                        </>
                                    )}
                                >
                                    {currentCampaign.campaignBalance}
                                </FormatMoney>
                            </span>
                        </Text>
                        {REGULAR_SPACE}
                        {trls[TrlKeys.outOf]}
                        {NON_BREAKING_SPACE}
                        <FormatMoney currency={currency} groupSeparator={NARROW_NON_BREAKING_SPACE} inCents>
                            {currentCampaign.budget}
                        </FormatMoney>
                    </Text>
                    <VSpacing base={1} />
                </div>
                {button}
            </div>
            {currentCampaign.campaignStatus === AuctionCampaignStatus.CampaignLowBalance ||
            currentCampaign.campaignStatus === AuctionCampaignStatus.AccountLowBalance ? (
                <Text>
                    <span className={styles.budgetStatusHint}>
                        {formatToReactComponent(trls[TrlKeys.hint[currentCampaign.campaignStatus]], {
                            '{0}': (
                                <FormatMoney currency={currency} inCents>
                                    {accountBalance}
                                </FormatMoney>
                            ),
                        })}
                    </span>
                </Text>
            ) : (
                <Text>
                    {trls[TrlKeys.enoughFor]}
                    {NON_BREAKING_SPACE}
                    <Conversion
                        value={bidRemainder}
                        one={trls[TrlKeys.click.one]}
                        some={trls[TrlKeys.click.some]}
                        many={trls[TrlKeys.click.many]}
                        zero={`0 ${trls[TrlKeys.click.many]}`}
                    />
                </Text>
            )}
        </div>
    );
};

export default translation(BudgetBanner);
