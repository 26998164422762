import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { H2 } from 'bloko/blocks/header';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FlexibleRow from 'lux/components/FlexibleRow';
import FormatMoney from 'lux/components/FormatMoney';
import translation from 'lux/components/translation';
import { AuctionFieldName } from 'lux/models/auctionData';

import styles from './price-product-auction.less';

const TrlKeys = {
    totalPriceTitle: 'employer.auction.price.product.total',
};

interface PriceProductAuctionProps {
    loading: boolean;
}

const PriceProductAuction: TranslatedComponent<PriceProductAuctionProps> = ({ loading, trls }) => {
    const budgetControlInfo = useSelectorNonNullable(
        ({ auctionData }) => auctionData.controls[AuctionFieldName.Budget]
    );
    const product = useSelectorNonNullable(({ auctionData }) => auctionData.productInfo.data);
    const hideClickmeBalance = useSelectorNonNullable(({ auctionData }) => auctionData.hideClickmeBalance);

    if (!product || budgetControlInfo.error) {
        return null;
    }

    return (
        <>
            {!hideClickmeBalance && (
                <>
                    <VSpacing base={4} />
                    <div className={styles.divider} />
                    <VSpacing base={4} />
                </>
            )}
            <section>
                <H2 lite>
                    <FlexibleRow>
                        <div className={styles.priceProductTextWrapper}>
                            <Text Element="p">{trls[TrlKeys.totalPriceTitle]}</Text>
                        </div>
                        <div className={styles.priceProductMoney}>
                            {loading ? (
                                <Loading scale={LoadingScale.Medium} />
                            ) : (
                                <FormatMoney currency={product.currency} inCents={true}>
                                    {product.price}
                                </FormatMoney>
                            )}
                        </div>
                    </FlexibleRow>
                </H2>
            </section>
        </>
    );
};

export default translation(PriceProductAuction);
