import { FC } from 'react';

import useFetchProduct from 'lux/components/Auction/hooks/useFetchProduct';
import MoneyInput, { MoneyInputProps } from 'lux/components/MoneyInput';
import { AuctionFieldError } from 'lux/models/auctionData';

interface ReplenishmentProps extends MoneyInputProps {
    error?: AuctionFieldError;
}

const Replenishment: FC<ReplenishmentProps> = ({ value, error, ...props }) => {
    useFetchProduct({ replenishment: Number(value), error });

    return <MoneyInput {...props} value={value} />;
};

export default Replenishment;
