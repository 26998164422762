import { useDispatch } from 'react-redux';

import Button, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import Loading from 'bloko/blocks/loading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { isPauseCampaignStatus, isRestoreAction } from 'lux/components/Auction/utils';
import { useNotification } from 'lux/components/Notifications/Provider';
import saveSuccessfullyAuctionModalNotification from 'lux/components/Notifications/VacancyAuctionSaveSuccessfullNotification';
import translation from 'lux/components/translation';
import useOnOffState from 'lux/hooks/useOnOffState';
import {
    AuctionCampaignStatus,
    AuctionFieldName,
    CurrentVacancyAuctionCampaign,
    defaultControlInfo,
} from 'lux/models/auctionData';
import { useSelector } from 'lux/modules/useSelector';

import { changeStatusRequest } from 'lux/components/Auction/Modals/EditAuctionModal/ChangeStatusButton/changeStatusRequest';

const TrlKeys = {
    restore: 'auction.modal.restore',
    pause: 'auction.modal.pause',
};

interface ChangeStatusButtonProps {
    auctionLoading: boolean;
    onRestoreCampaign: () => void;
    onStopCampaign: () => void;
    onCampaignInfoUpdate?: (campaign: Partial<CurrentVacancyAuctionCampaign>) => void;
}

const getParamsByStatus = (campaignStatus?: AuctionCampaignStatus) => {
    if (isPauseCampaignStatus(campaignStatus)) {
        return {
            newStatus: AuctionCampaignStatus.Active,
            appearance: ButtonAppearance.Filled,
            kind: ButtonKind.Primary,
            trlKey: TrlKeys.restore,
        } as const;
    }

    return {
        newStatus: AuctionCampaignStatus.Blocked,
        appearance: ButtonAppearance.Outlined,
        kind: undefined,
        trlKey: TrlKeys.pause,
    } as const;
};

const ChangeStatusButton: TranslatedComponent<ChangeStatusButtonProps> = ({
    auctionLoading,
    onRestoreCampaign,
    onStopCampaign,
    onCampaignInfoUpdate,
    trls,
}) => {
    const currentVacancyAuctionCampaign = useSelector(({ auctionData }) => auctionData?.currentVacancyAuctionCampaign);
    const { addNotification } = useNotification();
    const bidControl =
        useSelector(({ auctionData }) => auctionData?.controls[AuctionFieldName.Bid]) || defaultControlInfo;

    const [loading, startLoading, stopLoading] = useOnOffState(false);

    const dispatch = useDispatch();

    const editStatusButtonIsDisabled = !currentVacancyAuctionCampaign;
    const bidChanged = bidControl?.initialValue !== bidControl?.value;

    if (isRestoreAction(bidChanged, currentVacancyAuctionCampaign?.campaignStatus)) {
        return null;
    }

    const { newStatus, appearance, kind, trlKey } = getParamsByStatus(currentVacancyAuctionCampaign?.campaignStatus);

    const handleClickEditStatus = () => {
        startLoading();

        const onSuccessChangedStatus = (campaign: Partial<CurrentVacancyAuctionCampaign>) => {
            onCampaignInfoUpdate?.(campaign);
            if (isPauseCampaignStatus(currentVacancyAuctionCampaign?.campaignStatus)) {
                addNotification(saveSuccessfullyAuctionModalNotification);
                onRestoreCampaign();
            } else {
                onStopCampaign();
            }
        };

        dispatch(
            changeStatusRequest(
                currentVacancyAuctionCampaign?.campaignId as number,
                newStatus,
                onSuccessChangedStatus,
                addNotification
            )
        ).finally(stopLoading);
    };

    return (
        <Button
            appearance={appearance}
            kind={kind}
            disabled={editStatusButtonIsDisabled}
            loading={(auctionLoading || loading) && <Loading />}
            onClick={handleClickEditStatus}
            data-qa="auction-change-status"
        >
            {trls[trlKey]}
        </Button>
    );
};

export default translation(ChangeStatusButton);
