import React from 'react';

import { Tooltip } from '@hh.ru/magritte-ui';

export enum TooltipType {
    Click = 'click',
    Hover = 'hover',
}

export type ActionPropsType = React.ComponentProps<typeof Tooltip>['actionProps'];
