import auctionStatisticsAllButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/auction/auction_statistics_all_button_click';
import auctionStatisticsConversionButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/auction/auction_statistics_conversion_button_click';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { Link } from '@hh.ru/redux-spa-middleware';
import { HoverTip, TipProvider } from 'bloko/blocks/drop';
import { TipPlacement } from 'bloko/blocks/drop/Tip';
import { QuestionMarkScaleSmall, IconColor } from 'bloko/blocks/icon';
import BlokoLink from 'bloko/blocks/link';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import formatDate from 'Modules/formatDate';
import CampaignStatusLabel from 'lux/components/Auction/Common/CampaignStatus/CampaignStatusLabel';
import FlexibleRow from 'lux/components/FlexibleRow';
import translation from 'lux/components/translation';

import styles from './styles.less';

const TrlKeys = {
    status: 'auction.statistics.status',
    responses: 'auction.statistics.responses',
    clicks: 'auction.statistics.clicks',
    conversionToResponse: 'auction.statistics.conversionToResponse',
    startDate: 'auction.statistics.startDate',
    tooltipText: 'auction.statistics.tooltipText',
    all: 'auction.statistics.all',
};

interface StatisticsBannerProps {
    button?: JSX.Element;
}

const StatisticsBanner: TranslatedComponent<StatisticsBannerProps> = ({ trls }) => {
    const vacancyId = useSelectorNonNullable(({ auctionData }) => auctionData.vacancyId || '');
    const clicks = useSelectorNonNullable(({ auctionData }) => auctionData.auctionStatistics.clicks || '–');
    const responses = useSelectorNonNullable(({ auctionData }) => auctionData.auctionStatistics.responses || '–');
    const conversion = useSelectorNonNullable(({ auctionData }) =>
        auctionData.auctionStatistics.conversion ? `${auctionData.auctionStatistics.conversion}%` : '–'
    );
    const startAuctionDate = useSelectorNonNullable(({ auctionData }) =>
        auctionData.auctionStatistics.startAuctionDate
            ? formatDate(auctionData.auctionStatistics.startAuctionDate, 'd MMMM yyyy')
            : '–'
    );
    const linkToConversion = `/employer/statistics/vacancy_conversion/?vacancyId=${vacancyId}`;

    return (
        <div className={styles.root} data-qa="auction-statistics-banner">
            <div className={styles.content}>
                <div className={styles.status}>
                    <FlexibleRow>
                        <Text strong importance={TextImportance.Primary}>
                            {trls[TrlKeys.status]}
                        </Text>
                        <CampaignStatusLabel dataQaPrefix="edit-auction-modal" />
                    </FlexibleRow>
                    <VSpacing base={5} />
                </div>
                <VSpacing base={5} />
                <FlexibleRow>
                    <Text importance={TextImportance.Primary}>{trls[TrlKeys.clicks]}</Text>
                    <Text importance={TextImportance.Primary} data-qa="auction-statistics-banner-clicks">
                        {clicks}
                    </Text>
                </FlexibleRow>
                <VSpacing base={5} />
                <FlexibleRow>
                    <Text importance={TextImportance.Primary}>{trls[TrlKeys.responses]}</Text>
                    <Text importance={TextImportance.Primary} data-qa="auction-statistics-banner-responses">
                        {responses}
                    </Text>
                </FlexibleRow>
                <VSpacing base={5} />
                <FlexibleRow>
                    <div className={styles.conversion}>
                        <Text importance={TextImportance.Primary}>{trls[TrlKeys.conversionToResponse]}</Text>
                        <TipProvider>
                            <HoverTip
                                onHover={(show) => {
                                    if (show) {
                                        auctionStatisticsConversionButtonClick();
                                    }
                                }}
                                placement={TipPlacement.Bottom}
                                render={() => <Text>{trls[TrlKeys.tooltipText]}</Text>}
                            >
                                <QuestionMarkScaleSmall initial={IconColor.Gray60} />
                            </HoverTip>
                        </TipProvider>
                    </div>

                    <Text importance={TextImportance.Primary} data-qa="auction-statistics-banner-conversion">
                        {conversion}
                    </Text>
                </FlexibleRow>
                <VSpacing base={5} />
                <FlexibleRow>
                    <Text importance={TextImportance.Primary}>{trls[TrlKeys.startDate]}</Text>
                    <Text importance={TextImportance.Primary} data-qa="auction-statistics-banner-start-date">
                        {startAuctionDate}
                    </Text>
                </FlexibleRow>
            </div>
            <div>
                <BlokoLink
                    data-qa="auction-statistics-banner-link-all-statistics"
                    target="_blank"
                    rel="noopener noreferrer"
                    disableVisited
                    Element={Link}
                    to={linkToConversion}
                    onClick={() => auctionStatisticsAllButtonClick({ vacancyId })}
                >
                    {trls[TrlKeys.all]}
                </BlokoLink>
            </div>
        </div>
    );
};

export default translation(StatisticsBanner);
