import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import auctionHowItWorksButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/auction/auction_how_it_works_button_click';
import auctionModalCreateCampaignButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/auction/auction_modal_create_campaign_button_click';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind, ButtonAppearance } from 'bloko/blocks/button';
import { FormSpacer } from 'bloko/blocks/form';
import BlokoLink, { LinkAppearance } from 'bloko/blocks/link';
import Loading, { LoadingColor, LoadingScale } from 'bloko/blocks/loading';
import Modal, { ModalHeader, ModalTitle, ModalFooter, ModalContent } from 'bloko/blocks/modal';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AuctionContentShown from 'lux/components/Auction/Analytics/AuctionContentShown';
import BidRating from 'lux/components/Auction/Common/BidRating';
import AuctionControls from 'lux/components/Auction/Common/Controls';
import PriceProductAuction from 'lux/components/Auction/Common/PriceProductAuction';
import FlexibleRow, { FlexibleRowAlignItems } from 'lux/components/FlexibleRow';
import Label, { LabelType, LabelColor } from 'lux/components/Label';
import { useNotification } from 'lux/components/Notifications/Provider';
import translation from 'lux/components/translation';
import useOnOffState from 'lux/hooks/useOnOffState';
import {
    AuctionFieldName,
    defaultControlInfo,
    CampaignCreationStatus,
    setCreatedAuctionData,
    CreatedAuctionData,
} from 'lux/models/auctionData';
import { useSelector } from 'lux/modules/useSelector';
import {
    createCartWithClickmeAuctionCampaignDraft,
    createClickmeAuctionCampaignWithoutPurchase,
} from 'lux/requests/price/cart';

import styles from './styles.less';

const TrlKeys = {
    title: 'auction.title',
    description: 'auction.description',
    draftDescription: 'auction.creation.draftDescription',
    draftAction: 'auction.creation.draftAction',
    draftLabel: 'auction.creation.draftLabel',
    howItWorksTrigger: 'auction.howItWorks.trigger',
    cancel: 'Cancel',
    purchase: 'employer.auction.price.purchase',
    create: 'employer.myvacancies.auctionModal.create',
};

const voidFunction = () => undefined;

interface AuctionCreationModalProps {
    hideModal: () => void;
    loading?: boolean;
    showHowItWorksModal: () => void;
    visible: boolean;
    onCampaignCreated?: (data: CreatedAuctionData) => void;
    canClose: boolean;
}

const AuctionCreationModal: TranslatedComponent<AuctionCreationModalProps> = ({
    hideModal,
    loading,
    showHowItWorksModal,
    trls,
    visible,
    onCampaignCreated,
    canClose = true,
}) => {
    const bidControlInfo = useSelector(
        ({ auctionData }) => auctionData?.controls[AuctionFieldName.Bid] || defaultControlInfo
    );
    const budgetControlInfo = useSelector(
        ({ auctionData }) => auctionData?.controls[AuctionFieldName.Budget] || defaultControlInfo
    );
    const isCampaignDraft = useSelector(
        ({ auctionData }) => auctionData?.campaignCreationStatus === CampaignCreationStatus.CampaignDraftCreated
    );
    const draftPurchaseUrl = useSelector(({ auctionData }) => auctionData?.campaignDraft?.purchaseUrl);
    const product = useSelector(({ auctionData }) => auctionData?.productInfo.data || null);
    const productLoading = useSelector(({ auctionData }) => auctionData?.productInfo.loading || false);
    const vacancyId = useSelector(({ auctionData }) => auctionData?.vacancyId || null);
    const campaignDraftId = useSelector(({ auctionData }) => auctionData?.campaignDraft?.campaignDraftId);
    const campaignCreationStatus = useSelector(({ auctionData }) => auctionData?.campaignCreationStatus);
    const maxBidAmongSimilarVacancies = useSelector(
        ({ auctionData }) => auctionData?.bidRating.data.maxBidAmongSimilarVacancies
    );
    const useAvailableMoney = useSelector(({ auctionData }) => auctionData?.useAvailableMoney);
    const availableMoney = useSelector(({ auctionData }) => auctionData?.availableMoney);
    const { addNotification } = useNotification();

    const dispatch = useDispatch();

    const [disabled, setDisabledOn, setDisabledOff] = useOnOffState(false);

    const submitIsDisabled = loading || productLoading || (!useAvailableMoney && !product) || !vacancyId || disabled;

    const createCampaign = useCallback(() => {
        if (submitIsDisabled || bidControlInfo.error || budgetControlInfo.error) {
            return;
        }
        setDisabledOn();
        const currentBid = Number(bidControlInfo.value);
        const currentBudget = Number(budgetControlInfo.value);
        void dispatch(
            product
                ? createCartWithClickmeAuctionCampaignDraft(
                      [product],
                      {
                          httmFromLabel: 'clickmePopup',
                          source: 'clickmePopup',
                      },
                      vacancyId,
                      currentBid,
                      currentBudget,
                      addNotification
                  )
                : createClickmeAuctionCampaignWithoutPurchase(
                      vacancyId,
                      currentBid,
                      currentBudget,
                      (data) => {
                          dispatch(setCreatedAuctionData(data));
                          onCampaignCreated?.(data);
                          hideModal();
                      },
                      hideModal,
                      addNotification
                  )
        ).finally(setDisabledOff);
        auctionModalCreateCampaignButtonClick({
            vacancyId,
            campaignDraftId,
            campaignCreationStatus: campaignCreationStatus as CampaignCreationStatus,
            bidInCents: currentBid,
            budgetInCents: currentBudget,
            maxBidAmongSimilarVacancies: maxBidAmongSimilarVacancies as number,
            useAvailableMoney: !!useAvailableMoney,
            availableMoneyInCents: availableMoney ?? 0,
        });
    }, [
        submitIsDisabled,
        bidControlInfo.error,
        bidControlInfo.value,
        budgetControlInfo.error,
        budgetControlInfo.value,
        setDisabledOn,
        dispatch,
        product,
        vacancyId,
        addNotification,
        hideModal,
        setDisabledOff,
        campaignDraftId,
        campaignCreationStatus,
        maxBidAmongSimilarVacancies,
        useAvailableMoney,
        availableMoney,
        onCampaignCreated,
    ]);

    const onHowItWorksClick = useCallback(() => {
        auctionHowItWorksButtonClick({
            vacancyId,
            campaignDraftId,
            campaignCreationStatus: campaignCreationStatus as CampaignCreationStatus,
            // Тут ещё не создана кампания
            campaignId: undefined,
            campaignStatus: undefined,
        });
        showHowItWorksModal();
    }, [campaignCreationStatus, campaignDraftId, showHowItWorksModal, vacancyId]);

    return (
        <Modal
            visible={visible}
            onClose={canClose ? hideModal : voidFunction}
            closeButtonShow={canClose}
            escapePress={canClose}
        >
            <ModalHeader>
                <FlexibleRow>
                    <ModalTitle>{trls[TrlKeys.title]}</ModalTitle>
                    {isCampaignDraft && (
                        <Label
                            type={LabelType.CustomContent}
                            color={LabelColor.Yellow}
                            Content={trls[TrlKeys.draftLabel]}
                        />
                    )}
                </FlexibleRow>
            </ModalHeader>
            <ModalContent>
                <div className={styles.contentWrapper}>
                    {isCampaignDraft && draftPurchaseUrl ? (
                        <>
                            <div className={styles.draftAction}>
                                <FlexibleRow alignItems={FlexibleRowAlignItems.Start}>
                                    <Text>{trls[TrlKeys.draftDescription]}</Text>
                                    <Button
                                        kind={ButtonKind.Primary}
                                        appearance={ButtonAppearance.Outlined}
                                        Element={Link}
                                        to={draftPurchaseUrl}
                                    >
                                        {trls[TrlKeys.draftAction]}
                                    </Button>
                                </FlexibleRow>
                            </div>
                        </>
                    ) : (
                        <>
                            <Text>{trls[TrlKeys.description]}</Text>
                            <VSpacing base={1} />
                            <BlokoLink appearance={LinkAppearance.Pseudo} onClick={onHowItWorksClick}>
                                {trls[TrlKeys.howItWorksTrigger]}
                            </BlokoLink>
                        </>
                    )}
                    <VSpacing xs={10} base={8} />
                    {loading ? (
                        <div className={styles.centeringWrapper}>
                            <Loading scale={LoadingScale.Medium} />
                            <VSpacing xs={10} base={6} />
                        </div>
                    ) : (
                        <AuctionContentShown>
                            <BidRating />
                            <VSpacing xs={13} base={8} />
                            <AuctionControls />
                        </AuctionContentShown>
                    )}
                </div>
                {!loading && product && <PriceProductAuction loading={productLoading} />}
            </ModalContent>
            <ModalFooter>
                <FlexibleRow>
                    <div>
                        {isCampaignDraft && (
                            <BlokoLink appearance={LinkAppearance.Pseudo} onClick={showHowItWorksModal}>
                                {trls[TrlKeys.howItWorksTrigger]}
                            </BlokoLink>
                        )}
                    </div>
                    <div>
                        {canClose && <Button onClick={hideModal}>{trls[TrlKeys.cancel]}</Button>}
                        <FormSpacer>
                            <Button
                                data-qa="create-auction-button"
                                disabled={submitIsDisabled}
                                kind={ButtonKind.Primary}
                                loading={(loading || productLoading) && <Loading initial={LoadingColor.White} />}
                                onClick={createCampaign}
                            >
                                {product ? trls[TrlKeys.purchase] : trls[TrlKeys.create]}
                            </Button>
                        </FormSpacer>
                    </div>
                </FlexibleRow>
            </ModalFooter>
        </Modal>
    );
};

export default translation(AuctionCreationModal);
