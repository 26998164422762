import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import styles from './styles.less';

export enum FlexibleRowAlignItems {
    Start = 'start',
}

const FlexibleRow: FC<
    {
        alignItems?: FlexibleRowAlignItems;
        disableOnXS?: boolean;
    } & PropsWithChildren
> = ({ children, alignItems, disableOnXS }) => (
    <div
        className={classnames(styles.row, {
            [styles.rowDisableOnXs]: disableOnXS,
            [styles.rowAlignItemsStart]: alignItems === FlexibleRowAlignItems.Start,
        })}
    >
        {children}
    </div>
);

export default FlexibleRow;
