import { AuctionFieldError, AuctionFieldName } from 'lux/models/auctionData';

interface ValidateAuctionReplenishmentArgs {
    minReplenishment: number;
    replenishment: string;
}

type ErrorByAuctionFieldName = Record<AuctionFieldName, undefined | AuctionFieldError>;

export const validateAuctionReplenishment = ({
    replenishment,
    minReplenishment,
}: ValidateAuctionReplenishmentArgs): ErrorByAuctionFieldName => {
    const errors = {
        [AuctionFieldName.Replenishment]: undefined,
    } as ErrorByAuctionFieldName;

    if (replenishment === '') {
        errors[AuctionFieldName.Replenishment] = AuctionFieldError.ReplenishmentRequired;
    }
    if (replenishment && Number(replenishment) < minReplenishment) {
        errors[AuctionFieldName.Replenishment] = AuctionFieldError.MinReplenishmentValue;
    }
    if (replenishment && Number(replenishment) < minReplenishment) {
        errors[AuctionFieldName.Replenishment] = AuctionFieldError.MinReplenishmentValue;
    }
    if (replenishment && !errors[AuctionFieldName.Replenishment] && Number(replenishment) % 10000) {
        errors[AuctionFieldName.Replenishment] = AuctionFieldError.InvalidReplenishment;
    }

    return errors;
};
