import { useDispatch } from 'react-redux';

import { CellText } from '@hh.ru/magritte-ui';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import Checkbox from 'bloko/blocks/checkbox';
import { HoverTip } from 'bloko/blocks/drop';
import { TipLayer, TipPlacement } from 'bloko/blocks/drop/Tip';
import { QuestionMarkScaleSmall, IconColor, IconLink, IconVerticalAlignment } from 'bloko/blocks/icon';
import Text from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FieldTip from 'lux/components/FieldTip';
import CheckboxWithLabel from 'lux/components/VacancyCreate/common/CheckboxWithLabel';
import translation from 'lux/components/translation';
import { changeUseAvailableMoney } from 'lux/models/auctionData';
import { NON_BREAKING_SPACE } from 'lux/modules/symbols';
import { useSelector } from 'lux/modules/useSelector';

import ClickmeAvailableMoney from 'lux/components/Auction/Common/Controls/AvailableMoney/ClickmeAvailableMoney';

const TrlKeys = {
    availableMoney: 'auction.controls.availableMoney',
    accountBalanceInfo: 'auction.controls.accountBalance.info',
    accountBalanceInfoTitle: 'auction.controls.accountBalance.infoTitle',
};

interface AvailableMoneyProps {
    onUseAvailableMoneyChange?: (use: boolean) => void;
    redesigned?: boolean;
}

const AvailableMoney: TranslatedComponent<AvailableMoneyProps> = ({
    trls,
    onUseAvailableMoneyChange,
    redesigned = false,
}) => {
    const dispatch = useDispatch();
    const useAvailableMoney = useSelector(({ auctionData }) => auctionData?.useAvailableMoney || false);
    const availableMoney = useSelector(({ auctionData }) => auctionData?.availableMoney || 0);
    const currency = useSelectorNonNullable(({ auctionData }) => auctionData.currency);

    const toggleUseAvailableMoney = () => {
        dispatch(changeUseAvailableMoney(!useAvailableMoney));
        onUseAvailableMoneyChange?.(!useAvailableMoney);
    };

    if (redesigned) {
        return (
            <FieldTip tipContent={trls[TrlKeys.accountBalanceInfo]} fieldName={trls[TrlKeys.accountBalanceInfoTitle]}>
                <CheckboxWithLabel
                    name="useAvailableMoney"
                    title={
                        <CellText type="title">
                            {trls[TrlKeys.availableMoney]} {NON_BREAKING_SPACE}
                            <ClickmeAvailableMoney availableMoney={availableMoney} currency={currency} />
                        </CellText>
                    }
                    checked={useAvailableMoney && !!availableMoney}
                    data-qa="use-available-money"
                    disabled={!availableMoney}
                    onChange={toggleUseAvailableMoney}
                />
            </FieldTip>
        );
    }

    return (
        <>
            <Checkbox
                name="useAvailableMoney"
                checked={useAvailableMoney && !!availableMoney}
                data-qa="use-available-money"
                disabled={!availableMoney}
                onChange={toggleUseAvailableMoney}
            >
                {trls[TrlKeys.availableMoney]}
                {NON_BREAKING_SPACE}
                <ClickmeAvailableMoney availableMoney={availableMoney} currency={currency} />
            </Checkbox>
            {NON_BREAKING_SPACE}
            <HoverTip
                host={!process.env.LUX_SERVER ? document.body : null}
                layer={TipLayer.AboveOverlayContent}
                placement={TipPlacement.Right}
                render={() => <Text>{trls[TrlKeys.accountBalanceInfo]}</Text>}
            >
                <IconVerticalAlignment>
                    <IconLink>
                        <QuestionMarkScaleSmall initial={IconColor.Gray60} />
                    </IconLink>
                </IconVerticalAlignment>
            </HoverTip>
        </>
    );
};

export default translation(AvailableMoney);
